/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
import {setLanguage} from "../lang/i18";

const i18n = require('roddeh-i18n');

export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "uk";
export const localeOptions = [
	{id: "ru", name: "Русский", direction: "ltr"},
	{id: "uk", name: "Українська", direction: "ltr"}
];

export const apiPath = "https://laterem.ua";
export const imgPath = "https://laterem.ua/static/catalog/350x350/";
export const imgPathCatalog = "https://laterem.ua/static/catalogs_image/500x500/";

export const isMultiColorActive = true;
export const defaultColor = "light.orange";
export const defaultDirection = "ltr";
export const isDarkSwitchActive = false;
export const themeColorStorageKey = "__theme_color";
export const isDemo = false;

setLanguage(localStorage.getItem('currentLanguage'));

export const colorSearch = {'value': 0, 'label': i18n("Любой цвет")}

export const brandSearch = {'value': 0, 'label': i18n("Любой бренд")}
export const collectionSearch = {'value': 0, 'label': i18n("Любая коллекция")}
export const functionBidetSearch = {'value': 0, 'label': i18n("Любая функция")}
export const warehouseSetSearch = {'value': 0, 'label': i18n("Все склады")}
export const functionInstallationBathSearch = {'value': 0, 'label': i18n("Любая установка")}
export const installationSearch = {'value': 0, 'label': i18n("Любая установка")}
export const functionFormBathSearch = {'value': 0, 'label': i18n("Любая форма")}
export const specializationSearch = {'value': 0, 'label': i18n("Любая специализация")}
export const subCategorySearch = {'value': 0, 'label': i18n("Любая подкатегория")}
export const statusSearch = {'value': 0, 'label': i18n("Любой статус")}
export const categorySearch = {'value': 0, 'label': i18n("Любая категория")}

export const typeTileSearch = {'value': 0, 'label': i18n("Любой тип")}
export const applyingSearch = {'value': 0, 'label': i18n("Любое применение")}
export const textureSearch = {'value': 0, 'label': i18n("Любая текстура")}
export const elementSearch = {'value': 0, 'label': i18n("Любой елемент")}
export const numberBowlsSearch = {'value': 0, 'label': i18n("Любое количество")}
export const typeElementSearch = {'value': 0, 'label': i18n("Любой тип элемента")}
export const typeSystemSearch = {'value': 0, 'label': i18n("Любой тип системы")}
export const washbasinShapeSearch = {'value': 0, 'label': i18n("Любая форма")}
export const washbasinMaterialSearch = {'value': 0, 'label': i18n("Любая материал")}
export const elementSpaSearch = {'value': 0, 'label': i18n("Любой елемент")}
export const elementShowerSearch = {'value': 0, 'label': i18n("Любой елемент многофункционального душа")}
export const elementSaunaSearch = {'value': 0, 'label': i18n("Любой тип саун")}
export const elementAromaticSearch = {'value': 0, 'label': i18n("Любой тип ароматизации")}
export const typeSpaSearch = {'value': 0, 'label': i18n("Любой тип")}

export const advancedSearch = {
	value: '',
	productCode: '',
	productBrand: [brandSearch],
	productColor: [colorSearch],
	productTypeTile: [typeTileSearch],
	productApplying: [applyingSearch],
	productTexture: [textureSearch],
	productElement: [elementSearch],
	productNumberBowls: [numberBowlsSearch],
	functionBidet: [functionBidetSearch],
	functionFormBath: [functionFormBathSearch],
	functionInstallationBath: [functionInstallationBathSearch],
	productTypeElement: [typeElementSearch],
	productWashbasinMaterial: [washbasinMaterialSearch],
	productTypeSystem: [typeSystemSearch],
	productWashbasinShape: [washbasinShapeSearch],
	productElementSpa: [elementSpaSearch],
	productElementSauna: [elementSaunaSearch],
	productElementShower: [elementShowerSearch],
	productElementAromatic: [elementAromaticSearch],
	productTypeSpa: [typeSpaSearch],
	productSpecialization: [specializationSearch],
	productName: '',
	productStatus: [statusSearch],
	defaultPrice: [],
	productCategory: [categorySearch],
	productCollection: [collectionSearch],
	productSubCategory: [subCategorySearch],
	productInstallation: [installationSearch],
	valuesSwitch: false,
	isLoading: false,
	type: 0
};

export const warehouseOptions =  [
	{'label':i18n('Все склады'),'value':0},
	{'label':i18n('Киев'),'value':1},
	{'label':i18n('Тернополь'),'value':2},
	{'label':i18n('Ужгород'),'value':3},
	{'label':i18n('Одесса'),'value':4},
	{'label':i18n('Львов'),'value':5},
	{'label':i18n('Днепр'),'value':6}
];

export const advSearchConfig = [
	{
		category: "-1",
		show: {
			category: true,
			color: true,
			specialization: true,
			engineering: false,
			spa: false,
			tile: false
		}
	},
	{
		category: "23",
		show: {
			category: false,
			color: false,
			specialization: false,
			engineering: false,
			spa: false,
			tile: true
		}
	},
	{
		category: "24",
		show: {
			category: false,
			color: false,
			specialization: false,
			engineering: false,
			spa: true,
			tile: false
		}
	},
	{
		category: "31",
		show: {
			category: false,
			color: false,
			specialization: false,
			engineering: true,
			spa: false,
			tile: false
		}
	}
]

export const catName = {
	0:'plumbing',
	23:'tile',
	24:'spa',
	31:'engineering'
}
